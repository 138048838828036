body {
  font-family: "Poppins", sans-serif;
}

.arrow-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  width: 50px;
  height: 50px;
  font-size: 20px;
  background-color: #0b6885;
  color: #ffffff;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
}

.section-title {
  font-weight: 700;
  font-size: 29px;
}

.section-subtitle {
  font-size: 29px;
  color: #0b6885;
  font-weight: 700;
}

.section-text-about {
  overflow-y: auto;
  max-height: 325px;
  padding-right: 15px;
  text-align: justify;
  margin-right: -10px;
}

.section-text-about::-webkit-scrollbar {
  width: 6px;
}

.section-text-about::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  border-radius: 4px;
}

.section-text {
  margin-top: 10px;
  font-size: 15px;
  line-height: 22px;
}

.section-text-team {
  overflow-y: auto;
  max-height: 270px;
  padding-right: 15px;
  text-align: justify;
  margin-right: -10px;
}

.section-text-team::-webkit-scrollbar {
  width: 6px;
}

.section-text-team::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  border-radius: 4px;
}

.section-text-for_enterprise {
  margin-top: 10px;
  font-size: 15px;
  line-height: 21px;
}

.section-text-blog {
  min-height: 90px;
}

@media (max-width: 991px) {
  .section-text-blog {
    min-height: 128px;
  }
}

link:hover {
  text-decoration: none;
  color: #fff;
}

.link:hover {
  text-decoration: none;
  color: #fff !important;
}

.blue-btn {
  background: #0b6885;
  padding: 10px 25px;
  color: #fff;
  border: none;
  font-size: 16px;
  border-radius: 25px;
  font-weight: 600;
}

.dark-btn {
  padding: 10px 25px;
  color: #fff;
  font-size: 16px;
  border-radius: 25px;
  font-weight: 600;
  background: #1d4354;
  border: none;
}

.dark-btn:hover {
  background: #0b6885;
  transition: linear 0.2s background;
}

/* Header */
.header {
  padding: 15px 0px;
}

img.site-logo {
  height: 55px;
}

@media (max-width: 1200px) {
  img.site-logo {
    height: 45px;
    margin-top: 5px;
    width: 220px;
  }

  .navbar {
    margin-top: 10px;
    padding: 0px 0px !important;
  }
}

@media (max-width: 991) {
  img.site-logo {
    height: 55px;
  }
}

.main-menu ul li a {
  color: #222;
  font-weight: 500;
}

.log-btn {
  width: 100%;
  margin-top: 5px;
  font-size: 14px;
}

@media (max-width: 992px) {
  .log-btn {
    font-size: 10px;
  }
}

@media (max-width: 767px) {
  .log-btn {
    font-size: 14px;
  }
}

button.navbar-toggler.navbar-toggler.collapsed {
  margin-left: 80%;
}

.log-out-btn {
  width: 100%;
  margin-top: 5px;
  font-size: 14px;
}

@media (max-width: 992px) {
  .log-out-btn {
    font-size: 14px;
    margin-top: 10px;
  }
}

/*Slider Div */

.slider {
  background: #1d4354;
  color: #fff;
  padding-top: 50px;
}

.slider img {
  width: 100%;
}

.slider h2 {
  margin-top: 15px;
}

.slider p {
  margin-top: 30px;
  margin-bottom: 30px;
}

.slide-btn {
  border: solid 2px #fff;
  padding: 10px 25px;
  color: #fff;
  font-size: 17px;
  border-radius: 25px;
  font-weight: 600;
  background: #1d4354;
  margin-right: 15px;
}

.slide-btn:hover {
  background: #0b6885;
  transition: linear 0.2s background;
}

.active-btn {
  background: #0b6885;
  border: solid 2px #0b6885;
}

.about-process {
  padding: 60px 0px;
}

.about-process-enterprise {
  padding: 15px 0px;
}

.about-process-investor {
  padding: 50px 0px;
}

/* About Us */
.about {
  padding: 80px 0px;
}

.about img {
  width: 100%;
  border-radius: 20px;
  height: 450px;
  object-fit: cover;
}

.about h3 {
  margin: 30px 0px;
}

.about p:not(:first-child) {
  margin-top: 30px;
}

.about a {
  margin-top: 30px;
  display: inline-block;
}
/* Our Team*/

.about-team {
  padding: 65px 0px;
}

.about-team img {
  width: 100%;
  border-radius: 20px;
  /* height: 350px; */
  height: 400px;
  object-fit: fill;
}

.about-team h3 {
  font-size: 20px;
  padding: 5px 0px;
}

.about-team a {
  margin-top: 30px;
  display: inline-block;
}

/* Process */

.process {
  background: #edf1f5;
  padding: 60px 0px;
}

.process button {
  margin-top: 10px;
  margin-left: 15px;
}

.process-single {
  margin-top: 25px;
}

.process-single img {
  width: 100%;
  height: 220px;
  border-radius: 20px;
  object-fit: cover;
}

.process-single h2 {
  font-size: 22px;
  font-weight: 700;
  margin-top: 15px;
}

/* Home Listings */

.home-listings {
  padding: 80px 0px;
}

.home-listings-join_us {
  padding: 20px 0px;
}

.home-listings a {
  margin-top: 15px;
  display: inline-block;
}

.list-single {
  box-shadow: 0px 0px 15px #aaa;
  border-radius: 20px;
  padding-bottom: 15px;
  margin-top: 30px;
  transition: linear 0.1s transform;
}

.list-single:hover {
  transform: scale(1.1);
  transition: linear 0.2s transform;
}

.list-single img {
  width: 100%;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  height: 180px;
  object-fit: cover;
  margin-top: 0px;
}

.list-single a {
  margin-top: 0px;
  color: #111;
}

.list-single h3 {
  font-size: 16px;
  padding-left: 15px;
  padding-top: 15px;
}

/* Success Stories */
.success-story {
  padding: 80px 0px;
  background: #1d4354;
  padding-bottom: 60px;
}

.success-story h2,
.success-story h3 {
  color: #fff;
}

.story-single {
  background: #fff;
  border-radius: 20px;
  padding: 20px;
  margin-top: 30px;
  display: inline-block;
  width: 100%;
}

.story-single img {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  object-fit: cover;
  float: left;
  margin-right: 15px;
}

.story-single h3 {
  color: #1d4354;
  font-size: 22px;
  font-weight: 700;
  margin-top: 15px;
  display: inline-block;
}

.story-single p {
  margin-bottom: 30px;
}

.carousel-indicators {
  bottom: -80px;
}

/* News & Blog */
.news {
  padding: 80px 0px 40px 0px;
}

.news button {
  margin-top: 15px;
  margin-left: 15px;
}

.news-single img {
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-radius: 20px;
  margin-top: 15px;
}

.news-single {
  margin-top: 30px;
}

.news-single h2 {
  font-size: 18px;
  margin-top: 11px;
  font-weight: 700;
}

.news-single a {
  /* float: right; */
  color: #1d4354;
  font-weight: 700;
  margin-top: 0px;
}

.news-single h4 {
  color: #0b6885;
  font-size: 14px;
  font-weight: 700;
  margin-top: 15px;
}
/* Home FAQ */
.home-faq {
  background: #edf1f5;
  padding: 80px 0px;
}

.home-faq button {
  margin-top: 15px;
  margin-left: 15px;
}

.accordion > .card > .card-header {
  background: #fff;
  border-radius: 20px;
  margin-top: 30px;
  font-size: 111;
  color: #111;
  font-size: 24px;
}

.accordion button {
  margin-left: 0px;
  margin-top: 0px;
  color: #111;
  font-weight: 600;
  font-size: 20px;
}

.accordion > .card {
  background: none;
  border: none;
}

.card-body {
  background: #fff;
  margin-top: -15px;
  padding-top: 40px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 30px;
  max-height: 130px;
  overflow-y: auto;
  line-height: 30px;
  padding-bottom: 20px;
}

/*Form */

.short-form {
  background: #fff;
  padding: 25px;
  border-radius: 20px;
}

.short-form h3 {
  color: #0b6885;
  font-size: 20px;
  font-weight: 700;
}

.short-form input {
  width: 100%;
  padding: 10px 15px;
  border-radius: 15px;
  background: #edf1f5;
  border: none;
  margin-top: 20px;
}

.short-form textarea {
  width: 100%;
  padding: 10px 15px;
  border-radius: 15px;
  background: #edf1f5;
  border: none;
  margin-top: 20px;
  max-height: 150px;
  min-height: 150px;
}

input.send-btn {
  background: #0b6885;
  color: #fff;
  font-weight: 700;
}

/*Footer */
.footer {
  background: #1d4354;
  padding-top: 70px;
  color: #fff;
  padding-bottom: 40px;
}

.footer p {
  margin-top: 20px;
  line-height: 30px;
  font-size: 15px;
}

.footer h2 {
  font-size: 18px;
  font-weight: 700;
  margin-top: 30px;
}

.footer ul {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  margin-top: 30px;
}

.footer ul li a {
  display: block;
  margin-top: 12px;
  font-size: 14px;
}

/*Footer Credit */

.footer-credit {
  background: #1d4354;
  padding-top: 30px;
  padding-bottom: 70px;
  color: #fff;
  font-size: 15px;
}

.footer-right {
  text-align: right;
}

/** About Us page */
.page-title {
  background: #0b6885;
  padding: 40px 0px;
  color: #fff;
}

.page-title a {
  color: #eee;
  font-weight: 700;
}

.mission-single {
  background: #edf1f5;
  padding: 30px;
  border-radius: 20px;
  line-height: 30px;
  margin-top: 30px;
}

.mission-single h2 {
  color: #0b6885;
  font-weight: 700;
  font-size: 24px;
}

.team {
  margin-top: 80px;
}

.team h2 {
  color: #0b6885;
}

.team-single {
  background: #edf1f5;
  border-radius: 20px;
  text-align: center;
  padding: 30px;
  margin-top: 30px;
}

.team-single img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
}

.team-single h3 {
  font-size: 19px;
  font-weight: 700;
  margin-top: 15px;
}

.team-single h5 {
  margin-top: 5px;
  font-size: 16px;
  color: #0b6885;
  font-weight: 700;
}

/*About-us Our Team*/
.team-single-about-us {
  background: #edf1f5;
  border-radius: 20px;
  text-align: center;
  padding: 30px;
  margin-top: 30px;
}

.team-single-about-us:hover {
  background-color: #e1e5ea;
  cursor: pointer;
}
.team-single-about-us img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
}

.team-single-about-us h3 {
  font-size: 19px;
  font-weight: 700;
  margin-top: 15px;
}

.team-single-about-us h5 {
  margin-top: 5px;
  font-size: 16px;
  color: #0b6885;
  font-weight: 700;
}
/*About-us Our Team*/

.partner {
  padding: 80px 0px;
}

.partner h2 {
  color: #0b6885;
}

.partner-single {
  background: #fff;
  box-shadow: 0px 5px 15px #aaa;
  border-radius: 20px;
  padding: 20px;
  margin-top: 30px;
}

.partner-single img {
  width: 100%;
  object-fit: cover;
}

/* Contact Page */

.contact-page {
  padding: 80px 0px;
}

.contact-page h2 {
  font-weight: 700;
  margin-bottom: 20px;
  color: #0b6885;
}

.contact-page h3 {
  font-weight: 700;
  margin-bottom: 20px;
  color: #0b6885;
}

.contact-page p {
  color: #444;
}

.contact-page input {
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  border: none;
  font-size: 15px;
  margin-top: 25px;
  border: solid 1px #aaa;
}

.contact-page textarea {
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  border: none;
  font-size: 15px;
  margin-top: 25px;
  min-height: 200px;
  max-height: 200px;
  border: solid 1px #aaa;
}

.slide-send-btn {
  background: #0b6885;
  margin-top: 2% !important;
  width: 20% !important;
  float: right;
  /* display: inline-block; */
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  border: none;
  border-radius: 20px;
}

.contact-dtl {
  background: #ebebeb;
  padding: 30px;
  border-radius: 20px;
  margin-bottom: 30px;
}

.contact-dtl h5 {
  margin-top: 30px;
  font-weight: 700;
}

.contact-dtl a {
  font-size: 26px;
  margin-right: 30px;
}

/** News Page **/

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  background: #0b6885;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  border: solid 1px #0b6885;
}

.page-item .page-link {
  color: #0b6885; /* Replace with the color of your choice */
}

.pagination-container .page-item.active .page-link {
  background-color: #0b6885;
  border: solid 1px #0b6885;
  color: #fff;
}
/*Single Post */
.page-content-review {
  line-height: 30px;
}

.page-content-review h2,
.page-content-review h3 {
  font-weight: 700;
  color: #0b6885;
}
.page-content-detail {
  padding: 80px 0px;
  line-height: 30px;
}

.page-content-detail h2,
.page-content-detail h3 {
  font-weight: 700;
  color: #0b6885;
}
.page-content {
  padding: 80px 0px;
  line-height: 30px;
}

.page-content img {
  width: 100%;
  border-radius: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.page-content h2,
.page-content h3 {
  font-weight: 700;
  color: #0b6885;
}

.popular-single {
  border-bottom: solid 1px #aaa;
  padding: 20px 0px;
}

.popular-single h2 {
  color: #222;
  font-size: 18px;
}

.popular-single h4 {
  font-size: 16px;
  color: #1d4354;
}

/* Listings */
.listing-page {
  padding: 40px 0px;
}

.listing-page h3 {
  margin-top: 30px;
  font-weight: 700;
}

.filter-single {
  background: #edf0f5;
  padding: 20px;
  margin-top: 30px;
  border-radius: 20px;
  line-height: 32px;
  height: 270px; /* Set a fixed height for the div */
  overflow-y: hidden; /* Add a scrollbar for overflowed content */
}

.termscondition {
  overflow-y: scroll;
  max-height: 30rem;
  padding-right: 15px;
  text-align: justify;
  margin-right: -10px;
}

.termscondition::-webkit-scrollbar {
  width: 6px;
}

.termscondition::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  border-radius: 4px;
}

.filter-single h2 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}
/* Newly added css for checkbox label*/
.filter-single-notAuth {
  background: #edf0f5;
  padding: 20px;
  margin-top: 30px;
  border-radius: 20px;
  line-height: 32px;
}
/* New css for filter-single-amount*/
.filter-single-amount {
  background: #edf0f5;
  padding: 20px;
  margin-top: 30px;
  border-radius: 20px;
  line-height: 32px;
  height: 260px; /* Set a fixed height for the div */
  overflow-y: hidden; /* Add a scrollbar for overflowed content */
}

.filter-single-amount .MuiFormGroup-root {
  height: 100%;
  overflow-y: auto;
  flex-wrap: nowrap;
  flex-direction: column; /* Set the direction to column */
  align-items: flex-start; /* Align items to the start of the column */
}

.filter-single-amount .MuiFormGroup-root::-webkit-scrollbar {
  width: 8px;
}

.filter-single-amount .MuiFormGroup-root::-webkit-scrollbar-track {
  background: #edf0f5;
}

.filter-single-amount .MuiFormGroup-root::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.filter-single-amount .MuiFormGroup-root::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* New css for filter-single-amount*/

/* New css for filter-single-category*/
.filter-single-category {
  background: #edf0f5;
  padding: 20px;
  margin-top: 30px;
  border-radius: 20px;
  line-height: 32px;
  height: 265px; /* Set a fixed height for the div */
  overflow-y: hidden; /* Add a scrollbar for overflowed content */
}

.filter-single-category .MuiFormGroup-root {
  height: 89%;
  overflow-y: auto;
  overflow-x: hidden;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start of the column */
}

.filter-single-category .MuiFormGroup-root::-webkit-scrollbar {
  width: 8px;
}

.filter-single-category .MuiFormGroup-root::-webkit-scrollbar-track {
  background: #edf0f5;
}

.filter-single-category .MuiFormGroup-root::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.filter-single-category .MuiFormGroup-root::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* New css for filter-single-category*/

/* New css for filter-single-sdgs*/
.filter-single-sdgs {
  background: #edf0f5;
  padding: 20px;
  margin-top: 30px;
  border-radius: 20px;
  line-height: 32px;
}
/* New css for filter-single-sdgs*/

.filter-single .MuiFormGroup-root {
  height: 95%;
  overflow-y: auto;
  flex-wrap: nowrap;
  flex-direction: column; /* Set the direction to column */
  align-items: flex-start; /* Align items to the start of the column */
}

.scroll-bar-sdgs::-webkit-scrollbar {
  height: 10px;
}

.scroll-bar-sdgs::-webkit-scrollbar-thumb {
  border-radius: 20px;
}

.scroll-bar-sdgs::-webkit-scrollbar-track {
  background: #fff;
}

.checkbox-label .MuiTypography-root {
  font-size: 15px;
}

.filter-single .MuiFormGroup-root::-webkit-scrollbar {
  width: 8px;
}

.filter-single .MuiFormGroup-root::-webkit-scrollbar-track {
  background: #edf0f5;
}

.filter-single .MuiFormGroup-root::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.filter-single .MuiFormGroup-root::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* Newly added css for checkbox label*/

.deal-single_noauth {
  background: #edf0f5;
  padding: 20px;
  margin-top: 10px;
  border-radius: 20px;
}

.deal-single_noauth h2 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}

.deal-single_noauth a {
  display: block;
  margin-top: 20px;
  text-align: center;
}

.deal-single_noauth h3 {
  margin-top: 30px;
  font-size: 16px;
  font-weight: 700;
}

.deal-single {
  background: #edf0f5;
  padding: 20px;
  margin-top: 30px;
  border-radius: 20px;
}

.deal-single h2 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}

font.blue-color {
  color: #0b6885;
}

.deal-single a {
  display: block;
  margin-top: 20px;
  text-align: center;
}

.deal-single h3 {
  margin-top: 30px;
  font-size: 16px;
  font-weight: 700;
}

/* Login Div */
.userlog-div {
  margin-top: 30px;
  padding: 15px;
  font-weight: 600;
}

.userlog-div input {
  background: #edf0f5;
  width: 100%;
  border-radius: 15px;
  padding: 10px;
  border: none;
  margin-bottom: 15px;
  margin-top: 5px;
}

.userlog-div a {
  color: #222;
}

input.submit-btn {
  background: #0b6885;
  color: #fff;
  margin-top: 50px;
  font-weight: 700;
  font-size: 18px;
}

.modal-content {
  border-radius: 25px;
}

/* Investor & Enterprise Form */
.invest-form input {
  width: 100%;
  padding: 5px 10px;
  border-radius: 10px;
  border: solid 1px #aaa;
  margin-bottom: 15px;
}

.invest-form textarea {
  width: 100%;
  padding: 5px 10px;
  border-radius: 10px;
  border: solid 1px #aaa;
  margin-bottom: 15px;
  min-height: 200px;
  max-height: 200px;
}

.invest-form select {
  width: 100%;
  padding: 5px 10px;
  border-radius: 10px;
  border: solid 1px #aaa;
  margin-bottom: 15px;
  padding: 5px;
}

input.check-box {
  width: 20px;
}

/* Single Deal Form and interest form */

.dash-menus {
  background: #edf0f5;
  padding: 15px;
  border-radius: 25px;
  margin-top: 30px;
}

.dash-profile {
  text-align: center;
}

.dash-profile img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  border: solid 5px #0b6885;
}

.dash-profile h3 {
  font-size: 18px;
  font-weight: 700;
  margin-top: 15px;
}

.dash-menus ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  margin-top: 20px;
}

.dash-menus ul li a {
  display: block;
  border-top: solid 1px #aaa;
  padding: 10px;
  font-size: 16px;
  color: #111;
}

.dash-welcome {
  text-align: center;
  margin-top: 50px;
}

.dash-welcome a {
  background: #0b6885;
  padding: 10px 25px;
  border-radius: 20px;
  color: #fff;
  font-weight: 500;
  margin: 25px 10px 10px 10px;
}

.dash-welcome p {
  margin-bottom: 30px;
}

.dash-forms {
  display: block;
  margin-top: 30px;
}

.dash-form-note {
  width: 85%;
  margin-left: 10%;
}

.dash-form-single {
  background: #edf0f5;
  padding: 20px;
  border-radius: 20px;
  margin-top: 0px;
}

.dash-form-single a {
  display: block;
  text-align: right;
  color: #0b6885;
  font-weight: 500;
  font-size: 15px;
}

.dash-form-single h4 {
  font-size: 24px;
  font-weight: 600;
}

.dash-personal {
  background: #edf0f5;
  margin-top: 30px;
  padding: 30px 20px;
  border-radius: 20px;
}

.dash-personal h3 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 30px;
}

.dash-personal input {
  border-radius: 10px;
  width: 100%;
  padding: 5px;
  border: solid 1px #ccc;
  margin-bottom: 30px;
}

.single-deal {
  background: #edf0f5;
  padding: 30px;
  border-radius: 25px;
}

.single-deal h2 {
  color: #222;
  font-size: 24px;
}

.deal-details {
  margin-top: 30px;
}

.show-interest {
  background: #edf0f5;
  padding: 20px;
  border-radius: 25px;
}

.show-interest textarea {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  min-height: 150px;
  max-height: 150px;
}

.show-interest input {
  width: 100%;
  border-radius: 15px;
  margin-top: 30px;
}

.table-style {
  border-collapse: collapse;
  width: 100%;
}

.table-style th,
.table-style td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.table-style th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.error {
  color: red;
  font-size: 14px;
}

/* Our Team LinkedIn*/
a.linked-inn {
  color: #0e76a8;
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 10px;
}

a.linked-inn:hover {
  color: #222;
}
/* bootstrap css */
/* Change color of carousel controls */
.carousel-control-prev,
.carousel-control-next {
  margin-right: -120px;
  margin-left: -120px;
}

.navbar-light .navbar-nav .nav-link {
  color: black !important;
  font-weight: 500;
}

hr.dropdown-divider {
  background: aliceblue;
}

.dropdown-divider {
  height: 0 !important;
}

.my-nav-dropdown:hover .dropdown-menu {
  display: block;
}

/* bootstrap css */

.space-img {
  margin-bottom: -15px;
  display: flex;
  margin-top: -25px;
}
@media print {
  body * {
    visibility: hidden;
  }
  #printTable,
  #printTable * {
    visibility: visible;
  }
  #printTable {
    position: absolute;
    left: 0;
    top: 0;
  }

  .table-style {
    font-size: 14px;
  }

  .table-style th,
  .table-style td {
    padding: 8px;
    border: 1px solid #ddd;
  }

  .table-style th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
}

@media (max-width: 426) {
  .card-responsive {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 600px) {
  .list-single img {
    height: 240px;
  }

  .footer-right {
    text-align: left;
    margin-top: 20px;
  }

  .short-form {
    margin-top: 30px;
  }

  /* .accordion > .card {
    padding: 15px;
  } */

  .accordion > .card > .card-header {
    margin-top: 5px;
  }
}

@media (max-width: 1024px) {
  .carousel-control-next {
    display: none !important;
  }

  .carousel-control-prev {
    display: none !important;
  }
}

@media (max-width: 1024px) and (min-width: 992px) {
  .slider img {
    position: absolute;
    bottom: 0;
  }
}

/*----------------------------------Ckeditor css------------------------------------*/
.ck.ck-balloon-panel {
  z-index: 2000 !important; /* You may need to adjust this value */
}
